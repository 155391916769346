<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{
            $t("Add a Menu")
          }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <v-tabs
              v-model="daysTab"
              show-arrows
              class="user-tabs"
            >
              <v-tab
                v-for="(d, index) in item.days"
                :key="index"
                :value="`day${d.day}`"
                :href="`#day${d.day}`"
              >
                <span>{{ days.find(e => e.id === d.day).name }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              id="user-tabs-content"
              v-model="daysTab"
              class="mt-5 pa-1"
            >
              <v-tab-item
                v-for="(d, index) in item.days"
                :key="index"
                :value="`day${d.day}`"
              >
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="3"
                         v-for="(m, index) in item.menu_items.filter(ele => ele.day === d.day)"
                         :key="index">

                      {{ m.meal != null? m.meal.name[$i18n.locale] : 'Item'}}
                      <v-text-field
                        v-model="m.quantity"
                        :rules="[validators.required]"
                        min="0"
                        dense
                        hide-details
                        type="number"
                        outlined
                        :label="$t('Available Quantity')"
                        @input="m.error_quantity = null"
                      />
                  </v-col>
                </v-row>
              </v-tab-item>

            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
<!--          <v-btn
            :loading="loading"
            :disabled="item.days.length > 6"
            color="info"
            class="me-3"
            @click="addDay"
          >
            {{ $t("Add Day") }}
          </v-btn>-->
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import mealController from '@/views/apps/meals/Controller'
import IForm from '@/components/IForm'
import IAutocomplete from '@/components/IAutocomplete'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    IAutocomplete,
    IForm,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {
      },
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const {
      tableColumns,
    } = mealController()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormdayActive = ref(false)
    const loading = ref(false)
    const daysTab = ref(null)
    const meals = ref([])
    const isLoadingMeals = ref(false)
    const days = ref([
      { id: 1, name: i18n.t('Monday') },
      { id: 2, name: i18n.t('Tuesday') },
      { id: 3, name: i18n.t('Wednesday') },
      { id: 4, name: i18n.t('Thursday') },
      { id: 5, name: i18n.t('Friday') },
      { id: 6, name: i18n.t('Saturday') },
      { id: 7, name: i18n.t('Sunday') },
    ])
    const day = ref({})
    const isLoadingDays = ref(false)
    const search = ref('')
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const meal = computed({
      get: () => ({ name: search.value, shop_id: item.value.id }),
    })


    item.value.days = item.value.days || []
    item.value.menu_items = item.value.menu_items || []

    watch(item, val => {
      if (item.value.user && days.value.findIndex(ele => ele.id === item.user_id) < 0) {

      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeDay = (index, day) => {
      item.value.days.splice(index, 1)

      /* if (day.id != null) {
        axios.post(`/meals/action/delete_day/${item.value.id}`, { day_id: day.id })
      } */
    }
    const addDay = () => {
      item.value.days = item.value.days || []
      item.value.days.push({ start: 0, end: 23 })
    }
    const formatDate = (date, f) => format(date, f)
    const newDay = () => {
      item.value.user = {
        name: search.value, email: null, tell: null,
      }
      isFormdayActive.value = true
    }

    const onSubmit = () => {
      if (valid.value) {

        loading.value = true
        axios.post('/admin/shops', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data

            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }




    // onMounted(searchParticular)

    return {
      daysTab,
      day,
      minDate,
      resetItem,
      form,
      isFormdayActive,
      search,

      meals,
      meal,
      days,
      newDay,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      tableColumns,
      item,
      valid,
      loading,
      validate,
      avatarText,
      addDay,
      removeDay,
      isLoadingDays,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
